import httpClient from '@/libs/http-client'

export function getAttenList (params) {
  const url = '/api/member/attendance'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function setAttenToday (params) {
  const url = '/api/member/attendanceSave'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
